import { render, staticRenderFns } from "./DepositAdd.vue?vue&type=template&id=659246fa&scoped=true"
import script from "./DepositAdd.vue?vue&type=script&lang=js"
export * from "./DepositAdd.vue?vue&type=script&lang=js"
import style0 from "./DepositAdd.vue?vue&type=style&index=0&id=659246fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "659246fa",
  null
  
)

export default component.exports